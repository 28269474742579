
.margin-table[data-v-784bb5f0] {
  margin-left: 30px;
  margin-right: 30px;
}
[data-v-784bb5f0] .el-table th div.cell {
  background-color: #e8e8e8; /* 灰色背景色 */
  font-size: 16px;
  height: 30px;
  line-height: 30px; /* 设置行高使文字垂直居中 */
}
.blue-underline[data-v-784bb5f0] {
  text-decoration: underline;
  color: #409EFF;
  cursor: pointer; /* 添加鼠标悬停时的指针样式 */
}
